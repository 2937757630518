export default {
  components: {},

  data() {
    return {}
  },
  props: {
    creator: {
      type: Object,
    },
    image: {
      type: Object,
    },
  },

  mounted() {},

  beforeDestroy() {},

  watch: {},

  methods: {},
}
