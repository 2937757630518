import CardHero from '~/components/Molecules/Organic/CardHero/CardHero.vue'

export default {
  props: {
    list: Array,
  },

  components: {
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
  },

  data() {
    return {
      CardHero: CardHero,
      swiperOptions: {
        slidesPerView: 1.5,
        loop: true,
        speed: 7000,
        allowTouchMove: false,
        normalizeSlideIndex: false,
        centeredSlides: false,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        breakpoints: {
          768: {
            slidesPerView: 2.3,
          },
          475: {
            slidesPerView: 1.9,
          },
          375: {
            slidesPerView: 1.5,
          },
          320: {
            slidesPerView: 1.2,
          },
        },
      },
    }
  },
}
